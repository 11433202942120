import { Paper, Stack, TextField, Typography, useTheme, alpha, Button } from '@mui/material'
import { Box } from '@mui/system'
import React, { useState } from 'react'
import { AlertCircle } from 'react-feather';
import { useAuth } from '../../authentication/useAuth';
import IF from '../../components/IF';

const Login = () => {
    const { login } = useAuth();
    const [userName, setUserName] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState(null);
    const theme = useTheme();

    const handleLogin = async () => {
        try {
            await login({ userName, password });
        } catch (error) {
            setError(error.message);
        }
    };

    return (
        <Box
            width="100%"
            height="100vh"
            display="flex"
            flexDirection={'row-reverse'}
            justifyContent="space-between"
            alignItems="center"
            sx={{
                // background: 'linear-gradient(180deg, #002A3E 0%, #00547C 100%)',
            }}
        >
            <div
                style={{
                    width: '100%',
                    height: '100%',
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <img src="/Logo_Blue.png" alt="logo" style={{ width: '50%', maxWidth: '300px', height: 'auto' }} />
                <Typography sx={{position: 'absolute', bottom: 10, right: 10}} color="#000">Version 1.5.0</Typography>
            </div>
            <Paper
                sx={{
                    width: '100%',
                    height: '100%',
                    padding: theme.spacing(4),
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    background: 'linear-gradient(180deg, #002A3E 0%, #00547C 100%)',
                    boxShadow: `0px 0px 20px ${alpha('#000', 0.5)}`,
                }}
            >
                <Stack width="100%" direction="column" gap={4} justifyContent="space-between" alignItems="center">
                    <Typography
                        fontSize={30}
                        color={'#fff'}
                        textAlign="center"
                    >
                        Inventory Management System
                    </Typography>
                    <Stack gap={1} sx={{width: '100%', maxWidth: '300px'}}>
                        <Typography variant="caption" color={'#fff'}>Username</Typography>
                        <TextField value={userName} size='small' fullWidth onChange={(event) => setUserName(event.target.value)}
                            sx={{
                                '& .MuiInputBase-input': {
                                    backgroundColor: '#f5f5f5',
                                }
                            }} />
                        <Typography variant="caption" color={'#fff'}>Password</Typography>
                        <TextField type="password" size="small" value={password} onChange={(event) => setPassword(event.target.value)}  sx={{
                                '& .MuiInputBase-input': {
                                    backgroundColor: '#f5f5f5',
                                }
                            }}/>
                        <Button onClick={handleLogin} variant="contained" size="small" sx={{mt: 2}} >
                        
                            Login
                        </Button>
                        <IF condition={error}>
                            <Box display="flex" gap={2} alignItems="center">
                                <AlertCircle size={16} color={theme.palette.error.light} />
                                <Typography variant="caption" color="error.light">
                                    {error}
                                </Typography>
                            </Box>
                        </IF>
                    </Stack>
                </Stack>
            </Paper>
        </Box>
    )
}

export default Login