import { useTheme } from '@emotion/react';
import { Autocomplete, Box, Button, Paper, Stack, TextField, Typography, alpha } from '@mui/material';
import React, { useState } from 'react'
import { AlertCircle } from 'react-feather';
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../authentication/useAuth';
import IF from '../../components/IF';
import useIdleTimeout from '../../hooks/useIdleTimeout';
import PasswordPrompt from '../../PasswordPrompt';
import { setSelectedSite } from '../../store/global'
import { getAllItems } from '../Administration/InventoryItems/slice';
import { getAllSites } from '../Administration/Sites/slice';
import { getAllUsers } from '../Administration/Users/slice';
import { getAllVehicles } from '../Administration/Vehicles/slice';
import { getAllGRVs } from '../Operations/GRVs/slice';
import { getAllActiveJobCards } from '../Operations/JobCards/slice';

const SiteSelection = () => {
    const [error, setError] = useState("");
    const { companyId, siteAccess, logout } = useAuth();
    const [site, setSite] = useState(siteAccess?.length ? siteAccess[0] : "");
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const theme = useTheme();

    const handleSelectSite = () => {
        if (siteAccess.map(site => site._id).includes(site._id)) {
            dispatch(setSelectedSite(site));
            dispatch(getAllUsers(companyId));
            dispatch(getAllSites(companyId));
            dispatch(getAllItems(companyId, site.siteId));
            dispatch(getAllVehicles(companyId, site.siteId));
            dispatch(getAllActiveJobCards(companyId, site.siteId));
            dispatch(getAllGRVs(companyId, site.siteId));
            localStorage.setItem('selectedSite', JSON.stringify(site));
            navigate('/ims/operations/job-cards', { replace: true })
        }
        else {
            setError("You do not have access to this site");
        }
    }

    const [openModal, setOpenModal] = React.useState(false);

    const handleIdle = () => {
        setOpenModal(true);
    }

    const { idleTimer } = useIdleTimeout({ onIdle: handleIdle });

    const stay = () => {
        setOpenModal(false);
        idleTimer.reset();
    }

    const handleLogout = () => {
        logout();
        setOpenModal(false);
    }

    return (
        <Box
            width="100%"
            height="100vh"
            display="flex"
            justifyContent="space-between"
            flexDirection={'row-reverse'}
            alignItems="center"
            sx={{
            }}
        >
         <div
                style={{
                    width: '100%',
                    height: '100%',
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <img src="/Logo_Blue.png" alt="logo" style={{ width: '50%', maxWidth: '300px', height: 'auto' }} />
                <Typography sx={{position: 'absolute', bottom: 10, right: 10}} color="#000">Version 1.5.0</Typography>
            </div>

            <PasswordPrompt open={openModal} stay={stay} handleLogout={handleLogout} />
            <Paper
                sx={{
                    width: '100%',
                    height: '100%',
                    padding: theme.spacing(4),
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    background: 'linear-gradient(180deg, #002A3E 0%, #00547C 100%)',
                    boxShadow: `0px 0px 20px ${alpha('#000', 0.5)}`,
                }}
            >
                <Stack gap={4} sx={{width: '100%', maxWidth: '300px'}}>
                    <Typography
                        fontSize={30}
                        color={'#f5f5f5'}
                        textAlign="center"
                    >
                        Inventory Management System
                    </Typography>
                    {
                        siteAccess?.length ?
                            <>
                                <Autocomplete
                                    options={siteAccess ?? []}
                                    value={site ?? {}}
                                    size="small"
                                    getOptionLabel={(option) => option.siteName ?? ""}
                                    isOptionEqualToValue={(option, value) => option === value}
                                    onChange={(event, option) => setSite(option)}
                                    renderInput={(params) =>
                                        <TextField {...params} size="small" 
                                            sx={{
                                                '& .MuiInputBase-input': {
                                                    backgroundColor: '#f5f5f5',
                                                }
                                            }}
                                        />
                                    }
                                    sx={{
                                        '& .MuiAutocomplete-inputRoot': {
                                            backgroundColor: '#f5f5f5',
                                        },
                                    }}
                                    color='#f5f5f5'
                                />
                                <Button disabled={!site} onClick={handleSelectSite} variant="contained">Confirm</Button>
                                <IF condition={error}>
                                    <Box display="flex" gap={2} alignItems="center">
                                        <AlertCircle size={16} color={theme.palette.error.main} />
                                        <Typography variant="caption" color="error.main">
                                            {error}
                                        </Typography>
                                    </Box>
                                </IF>
                            </>
                            :
                            <>
                                <Typography textAlign="center">You do not have access to any sites. Please contact your administrator</Typography>
                                <Button variant="contained" onClick={logout}>Sign Out</Button>
                            </>

                    }
                </Stack>
            </Paper>
        </Box>
    )
}

export default SiteSelection