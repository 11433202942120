import { Drawer, Hidden } from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import { useAuth } from '../../authentication/useAuth';
import useIdleTimeout from '../../hooks/useIdleTimeout';
import PasswordPrompt from '../../PasswordPrompt';
import { setDrawerOpen } from '../../store/global';
import SiteSelection from '../../views/Login/siteSelection';
import NavBar from './NavBar';
import TopBar from './TopBar';
import { getAllPendingUploads } from './TopBar/slice';

const MainLayout = () => {
    const dispatch = useDispatch();
    const { companyId, logout } = useAuth();
    const { drawerOpen } = useSelector(state => state.global);
    const [openModal, setOpenModal] = React.useState(false);

    const handleIdle = () => {
        setOpenModal(true);
    }

    const { idleTimer } = useIdleTimeout({ onIdle: handleIdle });

    const stay = () => {
        setOpenModal(false);
        idleTimer.reset();
    }

    const handleLogout = () => {
        logout();
        setOpenModal(false);
    }

    dispatch(getAllPendingUploads(companyId));

    return (
        <>
            <PasswordPrompt open={openModal} stay={stay} handleLogout={handleLogout} />
            {
                !!localStorage.getItem('selectedSite') ? 
                    <div
                        style={{
                            display: 'flex',
                            height: '100%',
                            overflow: 'hidden',
                            width: '100%',
                        }}
                    > 
                        <TopBar/>
                        <Hidden mdDown >
                            <NavBar/>
                        </Hidden>
                        <Drawer 
                            open={drawerOpen}  
                            ModalProps={{ onBackdropClick: () => dispatch(setDrawerOpen()) }}
                        >
                            <NavBar />
                        </Drawer>
                        <div
                            style={{
                                display: 'flex',
                                flex: '1 1 auto',
                                overflow: 'hidden',
                                paddingTop: 64,
                                height: '100vh',
                                backgroundColor: '#f4f6f8',
                            }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    flex: '1 1 auto',
                                    overflow: 'hidden',
                                    height: '100%',
                                    width: '100%',
                                    padding: 16,
                                }}
                            >
                                <Outlet />
                            </div>
                        </div>
                    </div>
                
                : <SiteSelection/>
            }
        </>
    )
};

export default MainLayout;
