import { useTheme } from '@emotion/react'
import { Divider, Drawer, List, ListItem, Stack, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React, { Fragment } from 'react'
import { BarChart2, Calendar, DollarSign, Download, Grid, Package, User, Users } from 'react-feather'
import { useDispatch } from 'react-redux'
import { NavLink as RouterLink, useLocation } from 'react-router-dom'
import { useAuth } from '../../../authentication/useAuth'
import useIsTablet from '../../../hooks/useIsTablet'
import { setDrawerOpen } from '../../../store/global'

const sections = [
    
    {
        header: 'Operations',
        subSections: [
            {
                title: 'Job Cards',
                icon: Calendar,
                href: '/ims/operations/job-cards'
            },
            {
                title: 'Goods Received Vouchers',
                icon: Download,
                href: '/ims/operations/goods-received-vouchers'
            }
        ]
    }, 
    {
        header: 'Reports',
        subSections: [
            // {
            //     title: 'Inventory Movements',
            //     icon: Repeat,
            //     href: '/ims/reports/inventory-movements'
            // },
            // {
            //     title: 'Inventory Levels',
            //     icon: Sliders,
            //     href: '/ims/reports/inventory-levels'
            // },
            {
                title: 'Sales Analysis',
                icon: BarChart2,
                href: '/ims/reports/sales-analysis'
            },
            // {
            //     title: 'Diesel Usage',
            //     icon: Droplet,
            //     href: '/ims/reports/diesel-usage'
            // },
            {
                title: 'Payments Report',
                icon: DollarSign,
                href: '/ims/reports/payments-report'
            }
            // {
            //     title: 'Vehicle Services',
            //     icon: Truck,
            //     href: '/ims/reports/vehicle-services'
            // },

        ]
    },
    {
        header: 'Administration',
        subSections: [
            {
                title: 'Users',
                icon: User,
                href: '/ims/administration/users'
            },
            {
                title: 'Sites',
                icon: Grid,
                href: '/ims/administration/sites'
            },
            {
                title: 'Customers',
                icon: Users,
                href: '/ims/administration/customers'
            },
            {
                title: 'Inventory Items',
                icon: Package,
                href: '/ims/administration/inventory-items'
            },
            // {
            //     title: 'Vehicles',
            //     icon: Truck,
            //     href: '/ims/administration/vehicles'
            // }
        ]
    }
]

const NavBar = () => {
    const { user } = useAuth();
    const dispatch = useDispatch();
    const { pathname } = useLocation();
    const theme = useTheme();
    const isTablet = useIsTablet();

    const [, , , path] = pathname.split('/')
    const isSectionAuthorized = (section) => {
        let authorized = false;
        section?.subSections?.forEach(element => {
            const module = element.title.toLowerCase().replace(/ /g, "-");
            const check = user?.userPermissions?.find(p => p.module === module)?.view || false;
            if(check) authorized = true;
        });
        return authorized;
    };

    const isSubSectionAuthorized = (subsection) => {
        let authorized = false
        const module = subsection.title.replace(/ /g, "-").toLowerCase();
        const check = user?.userPermissions?.find(p => p.module === module)?.view || false;
        if (check) authorized = true;
        return authorized;
    }

    return (
        <Drawer
            open={true}
            variant="permanent"
            anchor='left'
            sx={{
                width: 256,
                top: 48,
                height: 'calc(100% - 48px)',
                overflowX: 'hidden',
                [`& .MuiDrawer-paper`]: {
                    width: 256,
                    top: 48,
                    height: 'calc(100% - 48px)',
                    overflowX: 'hidden',
                }
            }}
        >
            <Stack justifyContent={"space-between"}>
                <List>
                    {/* <ListItem 
                        style={{
                            mt: 1
                        }}
                    >
                        <Box
                            component={RouterLink}
                            to={'/ims'}
                            color={'inherit'}
                            display="flex"
                            width="100%"
                            justifyContent="flex-start"
                            alignItems="center"
                            p={1}
                            borderRadius={4}
                            ml={-1}
                            mr={1.5}
                            sx={
                                    ims === 'ims' && path==null ?
                                    {
                                        textDecoration: "none",
                                        backgroundColor: theme.palette.primary.main,
                                        '&.hover': {
                                            backgroundColor: theme.palette.action.hover
                                        }
                                    }:
                                    {
                                        textDecoration: "none",
                                        '&:hover': {
                                                backgroundColor: theme.palette.action.hover
                                            }
                                    }
                            }
                            onClick={ () => isTablet ? dispatch(setDrawerOpen()) : null}
                        >
                            <Typography color={ims === 'ims' && path==null ? "white" : "black"} fontSize={18}>Dashboard</Typography>
                        </Box>
                    </ListItem> */}
                    {sections.map((section, index) => {
                        return (
                            isSectionAuthorized(section) ?
                                <Fragment key={index}>
                                    <ListItem style={{mt: 1}}>
                                        <Typography fontWeight={500} fontSize={18}>{section.header}</Typography>
                                    </ListItem>
                                    <Box paddingLeft={1}>
                                        {section.subSections.map(sub => {
                                            const [, , , subRef] = sub.href.split('/');
                                            const Icon = sub.icon
                                            return (
                                                isSubSectionAuthorized(sub) ?
                                                    <ListItem sx={{py:'4px'}} disableGutters key={sub.href}>
                                                        <Box
                                                            component={RouterLink}
                                                            to={sub.href}
                                                            color={'inherit'}
                                                            display="flex"
                                                            width="100%"
                                                            justifyContent="flex-start"
                                                            alignItems="center"
                                                            p={1}
                                                            px={1.5}
                                                            borderRadius={1}
                                                            mr={1.5}
                                                            sx={
                                                                path === subRef ?
                                                                {
                                                                    textDecoration: "none",
                                                                    backgroundColor: theme.palette.primary.main,
                                                                    '&.hover': {
                                                                        backgroundColor: theme.palette.action.hover
                                                                    }
                                                                }
                                                                :
                                                                {
                                                                    textDecoration: "none",
                                                                    '&:hover': {
                                                                            backgroundColor: theme.palette.action.hover
                                                                        }
                                                                }
                                                            }
                                                            onClick={ () => isTablet ? dispatch(setDrawerOpen()) : null}
                                                        >
                                                            <Icon size={18} style={{paddingRight: 8, color: path===subRef ? "#fff" : "#000"}}/>
                                                            <Typography color={path===subRef ? "white" : "black"} fontSize={14}>{sub.title}</Typography>
                                                        </Box>
                                                    </ListItem>
                                                :   null
                                            )
                                        })}
                                    </Box>
                                    {
                                    index < sections.length - 1 ?
                                    <Divider variant='middle'  />
                                    : null  
                                    }
                                </Fragment>
                                : null
                        )})}
                </List>
                
                <Typography sx={{position: 'absolute', bottom: 10, left: 10}} color="#000">Version 1.5.0</Typography>
            </Stack>
        </Drawer>
    )
}

export default NavBar