import { createSlice } from '@reduxjs/toolkit';
import instance from '../../../utils/imsAxios';

const initialState = {
   jobCards: [],
   allJobCards: [],
   loadingJobCards: false,
};

export const JobCardSlice = createSlice({
    name: 'jobCards',
    initialState,
    reducers: {
        setJobCards: (state, action) => {
            state.jobCards = action.payload;
        },
        addJobCard: (state, action) => {
            state.jobCards.push(action.payload);
        },
        replaceJobCard: (state, action) => {
            const index = state.jobCards.findIndex(jobCard => jobCard._id === action.payload._id);
            if(index !== -1) {
                state.jobCards[index] = action.payload;
            }
        },
        setLoadingJobCards: (state, action) => {
            state.loadingJobCards = action.payload;
        },
        setAllJobCards: (state, action) => {
            state.allJobCards = action.payload;
        }

    }
});

export const getAllJobCards = (companyId, siteId) => async (dispatch) => {
    dispatch(JobCardSlice.actions.setLoadingJobCards(true));
    try {
        if (!companyId) return dispatch(JobCardSlice.actions.setAllJobCards(initialState.sites));
        const response = await instance.get('/jobCards/all', { params: { companyId: companyId, siteId: siteId } });
        dispatch(JobCardSlice.actions.setAllJobCards(response.data));
        dispatch(JobCardSlice.actions.setLoadingJobCards(false));
    } catch (error) {
        dispatch(JobCardSlice.actions.setLoadingJobCards(false));
        return dispatch(JobCardSlice.actions.setAllJobCards(initialState.sites));
    }
};

export const getAllActiveJobCards = (companyId, siteId) => async (dispatch) => {
    dispatch(JobCardSlice.actions.setLoadingJobCards(true));
    try {
        if (!companyId) return dispatch(JobCardSlice.actions.setJobCards(initialState.sites));
        const response = await instance.get('/jobCards', { params: { companyId: companyId, siteId: siteId } });
        dispatch(JobCardSlice.actions.setJobCards(response.data));
        dispatch(JobCardSlice.actions.setLoadingJobCards(false));
    } catch (error) {
        dispatch(JobCardSlice.actions.setLoadingJobCards(false));
        return dispatch(JobCardSlice.actions.setJobCards(initialState.sites));
    }
};

export const getDeletedJobCards = (companyId, siteId) => async (dispatch) => {
    dispatch(JobCardSlice.actions.setLoadingJobCards(true));
    try {
        if (!companyId) return dispatch(JobCardSlice.actions.setJobCards(initialState.sites));
        const response = await instance.get('/jobCards/deleted', { params: { companyId: companyId, siteId: siteId } });
        dispatch(JobCardSlice.actions.setJobCards(response.data));
        dispatch(JobCardSlice.actions.setLoadingJobCards(false));
    } catch (error) {
        dispatch(JobCardSlice.actions.setLoadingJobCards(false));
        return dispatch(JobCardSlice.actions.setJobCards(initialState.sites));
    }
}

export const getArchivedJobCards = (companyId, siteId) => async (dispatch) => {
    dispatch(JobCardSlice.actions.setLoadingJobCards(true));
    try {
        if (!companyId) return dispatch(JobCardSlice.actions.setJobCards(initialState.sites));
        const response = await instance.get('/jobCards/archived', { params: { companyId: companyId, siteId: siteId } });
        dispatch(JobCardSlice.actions.setJobCards(response.data));
        dispatch(JobCardSlice.actions.setLoadingJobCards(false));
    } catch (error) {
        dispatch(JobCardSlice.actions.setLoadingJobCards(false));
        return dispatch(JobCardSlice.actions.setJobCards(initialState.sites));
    }
}

export const {setJobCards, addJobCard, replaceJobCard} = JobCardSlice.actions;

export default JobCardSlice.reducer;
